export default [
  {
    id: 1,
    title: `Здійснити замовлення товарів на сайті
   <a href="https://cogu.com.ua"> https://cogu.com.ua</a>`,
  },
  {
    id: 2,
    title: `З вами зв'яжеться наш менеджер та запропонує вам зручним для вас шляхом відправити документи, 
    що підтверджують статус юридичної особи`,
  },
  {
    id: 3,
    title: `Менеджер направляє на підписання договір на поставку товару, виставляє рахунок на оплату зазначеного товару`,
  },
  {
    id: 4,
    title: `Юридична особа/ФОП підписує договір, надсилає скан-копію і здійснює 100% оплату товару згідно виставленого рахунку`,
  },
  {
    id: 5,
    title: `Після оплати відправити зручним для вас шляхом скан-копію Довіреності на отримання товарно-матеріальних цінностей`,
  },
  {
    id: 6,
    title: `Відвантаження товару здійснюється: службою доставки/ перевізниками за рахунок покупця, якщо інше не обумовлено заздалегідь`,
  },
  {
    id: 7,
    title: `Менеджер відправляє видаткові документи юридичній особі поштою або кур'єрською службою (за рахунок покупця)`,
  },
  {
    id: 8,
    title: `Покупець отримує оригінали видаткових документів та підписані примірники відправляє назад продавцю`,
  },
];
