import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../Container/Container';

import instructionData from '../../constants/instructionData';

import InfoIcon from '../../assets/icons/info-blue.svg';

import './Instruction.scss';

const CONTACTS_QUERY = graphql`
  query {
    contacts: allCockpitContacts(filter: { lang: { eq: "ua" } }) {
      nodes {
        icon {
          value
        }
        value {
          value
        }
      }
    }
  }
`;

const Instruction: React.FC = () => {
  const {
    contacts: { nodes: contactsNodes },
  } = useStaticQuery(CONTACTS_QUERY);
  return (
    <div className="instruction">
      <Container>
        <h2 className="instruction__title">Інструкція для юридичних осіб</h2>
        <p className="instruction__description">Як правильно зробити і оформити замовлення в магазині, якщо ви Юридична особа/ФОП</p>

        <div className="instruction__items-top">
          {instructionData.slice(0, 4).map(({ id, title }) => (
            <div className="instruction__items-item" key={id + title}>
              <h2>{id}</h2>
              <p dangerouslySetInnerHTML={{ __html: title }} />
              {id === 2 && (
                <div className="instruction__items-item-info">
                  <InfoIcon />
                  <div className="instruction__items-item-info-text">
                    <p>Свідоцтво платника ПДВ</p>
                    <p>Витяг щодо державної реєстрації підприємства</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="instruction__items-bottom">
          {instructionData.slice(4).map(({ id, title }) => (
            <div className="instruction__items-item" key={id + title}>
              <h2>{id}</h2>
              <p dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          ))}
        </div>
        <div className="instruction__consult">
          <h2>
            У разі виникнення питань можна звертатися
            <br /> за вказаними контактними даними
          </h2>
          <div className="instruction__consult-items">
            {contactsNodes.slice(0, 2).map(({ icon, value }) => (
              <div className="instruction__consult-item" key={value.value}>
                <div dangerouslySetInnerHTML={{ __html: icon.value }} />
                <p>
                  <span className={!value.value.includes('@') && `binct-phone-number-3`}>{value.value}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
    </div>
  );
};

export default Instruction;
