import React from 'react';

import Seo from '../components/SEO/SEO';

import { SEO_ITEMS } from '../constants/SEOItems';
import Instruction from '../components/Instruction/Instruction';
import Subheader from '../components/Subheader/Subheader';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Інструкція для юридичних осіб',
    link: '/instruction',
  },
];

const InstructionPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.instructionPage.description}
        lang="ua"
        path="/instruction"
        title={SEO_ITEMS.instructionPage.title}
      />
      <Subheader crumbs={crumbs} withContainer />
      <Instruction />
    </>
  );
};

export default InstructionPage;
